<template>
  <div class="jxDataSocre">
    <van-nav-bar :title="$t('jxDataSocre.socTxt1')"
                 :fixed="true"
                 left-text
                 left-arrow
                 @click-left="onClickLeft" />
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <van-search v-model="searchVal"
                shape="round"
                :placeholder="'搜索'"
                :clearable="true"
                @search="onSearch" />
    <div class="container">
      <div class="content"
           v-for="(value,key) in linkData"
           :key="key">
        <div class="myTag"
             v-if="value.pjstatus">
          <van-tag mark
                   size="medium"
                   :color="value.pjstatus == '待评价'?'#FFB366':'#7FBAF6'"
                   type="primary">{{value.pjstatus}}</van-tag>
        </div>
        <div class="flagname"
             v-if="value.checkflagname">
          <van-tag mark
                   size="medium"
                   :color="value.pjstatus == '待评价'?'#FFB366':'#7FBAF6'"
                   type="primary">{{value.checkflagname}}</van-tag>
        </div>
        <van-cell @click="goPage(value,key)"
                  class="vancelllist"
                  :title="value.empname+'-'+value.khtimename"
                  :value="value.lastscore+$t('jxDataSocreDetail.txt5')"
                  :label="value.planname"
                  is-link />
      </div>
    </div>
  </div>
</template>
<script>
import { getKpiPlanCheckList } from '@api/wxjx.js'
export default {
  name: "jxDataSocre",
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      linkData: [],
      searchVal: ""
    }
  },
  watch: {
    searchVal (n) {
      this.onSearch()
    }
  },
  mounted () {
    this.getKpiPlanCheckLists()
  },
  methods: {
    onSearch () {
      if (this.searchVal)
      {
        this.linkData = this.linkData.filter(
          item => item.khtimename.indexOf(this.searchVal) > -1 || item.planname.indexOf(this.searchVal) > -1 || item.empname.indexOf(this.searchVal) > -1
        )
      } else
      {
        this.getKpiPlanCheckLists()
      }
    },
    getKpiPlanCheckLists () {
      getKpiPlanCheckList({ empid: this.userInfo.empid }).then(res => {
        console.log(res);
        this.linkData = res.data;
      })
    },
    onClickLeft () {
      this.$router.push("/home");
    },
    goPage (value, key) {
      this.$router.push({
        path: "/jxDataSocreDetail",
        query: {
          autoid: value.autoid,
          checkflag: value.checkflag,
          is_zp: value.is_zp,
          is_zjp: value.is_zjp,
          is_jjp: value.is_jjp,
          can_pj_dl: value.can_pj_dl,
          up_detail_atfile: value.up_detail_atfile,
          up_master_atfile: value.up_master_atfile,
          usejj_forid: value.usejj_forid,
          usezj_forid: value.usezj_forid,
          zj_pj_memo: value.zj_pj_memo,
          jj_pj_memo: value.jj_pj_memo
        }
      });
      //  to="/jxDataSocreDetail"
    }
  }
}
</script>
<style lang="less" scoped>
.jxDataSocre {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    ::v-deep .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    ::v-deep .van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .container {
    width: 100%;
    height: calc(100% - 200px);
    overflow-y: auto;
    .content {
      position: relative;
      .myTag {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        .van-tag--mark {
          border-radius: 0 20px 20px 0;
        }
      }
      .flagname {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        display: flex;
        .van-tag--mark {
          border-radius: 20px 0 0 20px;
        }
      }
      .vancelllist {
        margin-top: 36px;
        padding-bottom: 39px !important;
        padding-top: 39px !important;
        span {
          font-weight: bold !important;
        }
      }
      ::v-deep .van-cell__value {
        color: #2b8df0;
      }
      ::v-deep .van-cell__title {
        flex: 5 !important;
      }
    }
  }
}
</style>